import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import BidStatus from './features/BidStatus';
import NotFound from './components/NotFound';
import CancelRequest from './features/CancelRequest';
import TieredCancelRequest from './features/TieredCancelRequest';
import RequirementStatus from './features/RequirementStatus';
import AlreadyTaken from './components/AlreadyTaken';
import InstallUpdate from './features/InstallUpdate/index';
import TierManage from './features/tierManage';
import TieredBidStatus from './features/TieredBidStatus';
import DeclineTieredBid from './features/DeclineTieredBid';
import TieredConfirmationAccepted from './features/TieredConfirmationAccepted';
import TieredConfirmationDeclined from './features/TieredConfirmationDeclined';
import QmeritTermsPayingWithStripe from './features/QmeritTermsPayingWithStripe';
import UserAcceptanceTest from './features/UserAcceptanceTest';
import TieredSubmitPermit from './features/TieredSubmitPermit';
import TieredAcceptCustom from './features/TieredAcceptCustom';
import TieredSubmitQuote from './features/TieredSubmitQuote';
import { PaymentConfirmation } from './features/paymentConfirmation';
import TieredRemainingBalance from './features/TieredRemainingBalance';
import DepositSuccess from './components/DepositSuccess';
import FinalPaymentSuccess from './components/FinalPaymentSuccess';
import TieredSubmitPayment from './features/TieredSubmitPayment';
import BidAccept from './features/BidAccept';
import ProjectStatusTracker from './features/ProjectStatusTracker';
import UpfrontPricePage from './features/UpfrontPricePage';
import QuotePage from './features/QuotePage';
import UnderConstruction from './features/UnderConstruction';
import FinancedRequestSubmitted from './features/FinancedRequestSubmitted';
import QuoteFinancingPage from './features/QuoteFinancingPage';
import QuoteFleetReviewPage from './features/QuoteFleetReviewPage';
import ManagerApproved from './features/ManagerApproved';
import ManagerDeclined from './features/ManagerDeclined';
import ManagerCanceled from './features/ManagerCanceled';
import LoadCalculationForm from './features/LoadCalculationForm';
import TermsOfUse from './features/MarketPlaceTermsOfUse';
import DepositReceived from './features/MarketPlaceDepositReceived';

const Router = () => (
  <Switch>
    <Route exact path="/bid-status" component={BidStatus} />
    <Route exact path="/requirements-status" component={RequirementStatus} />
    <Route exact path="/taken" component={AlreadyTaken} />
    <Route exact path="/tiered-cancel-request" component={TieredCancelRequest} />
    <Route exact path="/install-update" component={InstallUpdate} />
    <Route exact path="/tiered-bid-decline" component={DeclineTieredBid} />
    <Route exact path="/tiered-confirmation-accepted" component={TieredConfirmationAccepted} />
    <Route exact path="/tiered-confirmation-declined" component={TieredConfirmationDeclined} />
    <Route exact path="/tiered-tou-deposit" component={QmeritTermsPayingWithStripe} />
    <Route exact path="/tiered-remaining-balance" component={TieredRemainingBalance} />
    <Route exact path="/tiered-submit-payment" component={TieredSubmitPayment} />
    <Route exact path="/tiered-deposit-submitted" component={DepositSuccess} />
    <Route exact path="/tiered-payment-submitted" component={FinalPaymentSuccess} />
    <Route exact path="/tiered-payment-confirmed" component={PaymentConfirmation} />
    <Route exact path="/bid-accept" component={BidAccept} />
    <Route exact path="/project-status-tracker" component={ProjectStatusTracker} />
    <Route exact path="/preliminary-estimate" component={UpfrontPricePage} />
    <Route exact path="/installation-quote" component={QuotePage} />
    <Route exact path="/under-construction" component={UnderConstruction} />
    <Route exact path="/financed-request-submitted" component={FinancedRequestSubmitted} />
    <Route exact path="/finance-option" component={QuoteFinancingPage} />
    <Route exact path="/fleet-review" component={QuoteFleetReviewPage} />
    <Route exact path="/manager-approved" component={ManagerApproved} />
    <Route exact path="/manager-declined" component={ManagerDeclined} />
    <Route exact path="/manager-canceled" component={ManagerCanceled} />
    <Route exact path="/load-calculation" component={LoadCalculationForm} />
    <Route exact path="/tou" component={TermsOfUse} />
    <Route exact path="/deposit-success" component={DepositReceived} />
    {/* Tiered Routes - Currently not used by Pod1 */}
    {/* Currently not used - Replaced by /tiered-cancel-request */}
    <Route exact path="/cancel-request" component={CancelRequest} />
    {/* --------- Currently not used by Pod1 --------- */}
    <Route exact path="/tiered-uat" component={UserAcceptanceTest} />
    {/* Currently not used - Replaced by /bid-accept */}
    <Route exact path="/tiered-submit-permit" component={TieredSubmitPermit} />
    {/* Currently not used - Replaced by /bid-accept */}
    <Route exact path="/tiered-accept-custom" component={TieredAcceptCustom} />
    {/* Currently not used - Quote is now submitted through Core */}
    <Route exact path="/tiered-submit-quote" component={TieredSubmitQuote} />
    {/* --------- Currently not used by Pod1 --------- */}
    <Route exact path="/tiered-manage" component={TierManage} />
    {/* Currently not used - Replaced by /bid-accept */}
    <Route exact path="/tiered-bid-accept" component={TieredBidStatus} />
    <Route path="/" component={NotFound} />
  </Switch>
);

export default Router;
