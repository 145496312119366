export function formatCurrencyWithFraction(userLocale, currencyType, number) {
  const options = {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat(userLocale, options).format(number);
}

export function formatCurrencyWithNoFraction(userLocale, currencyType, number) {
  const options = {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  return new Intl.NumberFormat(userLocale, options).format(number);
}

export function formatNumberWithFraction(userLocale, number) {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat(userLocale, options).format(number);
}

export function getErrorCurrency(needsReview, number, userLocale, currencyType) {
  if (needsReview) {
    if (Number.isSafeInteger(number)) {
      return formatCurrencyWithNoFraction(userLocale, currencyType, number);
    } else {
      return formatCurrencyWithFraction(userLocale, currencyType, number);
    }
  }
  return null;
}

export function calculateCustomerPays(subtotal, permitCredit, sponsoredCredit) {
  return subtotal - permitCredit - sponsoredCredit;
}

export function getCustomerPays(parameters) {
  const { customerPays, needsReview, bidStatus, userLocale, currencyType, subtotal, permitCredit, notToExceed, total } =
    parameters;

  if (customerPays === -1) {
    // page has just rendered and/or input has not been changed
    if (needsReview || bidStatus === 'declined') {
      const defaultCustomerPays = calculateCustomerPays(subtotal, permitCredit, notToExceed);
      return formatCurrencyWithFraction(userLocale, currencyType, defaultCustomerPays);
    } else {
      return formatCurrencyWithFraction(userLocale, currencyType, total);
    }
  }
  return formatCurrencyWithFraction(userLocale, currencyType, customerPays);
}

export function getNteForReviewedQuote(bidStatus, sponsoredCredit, bidListSponsoredCredit) {
  switch (bidStatus) {
    case 'submitted':
      return bidListSponsoredCredit; // quote submitted by SP & reviewed by FM **(come from bid list)
    case 'confirmed':
      return sponsoredCredit; // quote approved by Customer
    case 'declined':
      return sponsoredCredit; // bid/installation declined by SP
    case 'reopened':
      return sponsoredCredit; // quote revised by SP after Customer approval
    default:
      return 'Error: unhandled status';
  }
}

export function getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold) {
  if (customerQuoteAmountThreshold !== null && customerQuoteAmountThreshold > 0) {
    return customerQuoteAmountThreshold;
  }

  if (sponsoredCredit !== null && sponsoredCredit > 0) {
    return sponsoredCredit;
  }

  return programQuoteAmountThreshold;
}

export function getNotToExceed(
  needsReview,
  bidStatus,
  userLocale,
  sponsoredCredit,
  programQuoteAmountThreshold,
  customerQuoteAmountThreshold,
  bidListSponsoredCredit
) {
  if (needsReview) {
    return getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold);
  } else {
    return getNteForReviewedQuote(bidStatus, sponsoredCredit, bidListSponsoredCredit);
  }
}

export function disableCancelProjectButton(bidStatus) {
  switch (bidStatus) {
    case 'cancelled':
      return true; // project already canceled by FM or Customer
    case 'confirmed':
      return true; // installation started - project cannot be canceled at this stage
    default:
      return false;
  }
}

export function getDisabledButtonText(currentBidStatus) {
  switch (currentBidStatus) {
    case 'submitted':
      return 'Saved & Approved';
    case 'confirmed':
      return 'Saved & Approved';
    case 'reopened':
      return 'Saved & Approved';
    default:
      return 'Declined';
  }
}

export function getDisabledButtonStyle(currentBidStatus) {
  switch (currentBidStatus) {
    case 'submitted':
      return 'green';
    case 'confirmed':
      return 'green';
    case 'reopened':
      return 'green';
    default:
      return 'red';
  }
}
